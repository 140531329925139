import type { JSX } from 'solid-js'
import { Show } from 'solid-js'

import { AuthGuard } from './AuthGuard'
import { useAuth } from './AuthProvider'

export const AdminGuard = (props: {
  children: JSX.Element
  fallback?: JSX.Element
}): JSX.Element => {
  const authState = useAuth()

  const isAdmin = () => authState.roles?.includes('ADMIN')

  return (
    <AuthGuard>
      <Show when={!authState.isLoading && isAdmin()}>{props.children}</Show>
      <Show when={!authState.isLoading && !isAdmin()}>{props.fallback}</Show>
    </AuthGuard>
  )
}
